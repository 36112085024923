const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export default date => {
  const jsDate = new Date(date)

  const month = MONTHS[jsDate.getMonth()]

  const year = jsDate.getFullYear()

  return `${month} ${year}`
}
