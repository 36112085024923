import React, { useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import PictureGrid from '../components/PictureGrid'
import getWorkingDays from '../utilities/getWorkingDays'
import getMonthAndYear from '../utilities/getMonthAndYear'
import { Clock, Calendar, ChevronLeft } from '../components/Icons'
import { useQueryParam } from 'gatsby-query-params'
import SEO from '../components/seo'
import METADATA from '../metadata/metadata'
import './project.scss'

const Project = ({
  data: {
    sanityProject: {
      categories,
      pictures,
      street,
      date_started,
      date_completed,
      testimonial,
    },
    allSanityTestimonial: testimonials,
  },
}) => {
  const matchedTestimonial = testimonials.nodes.find(t => {
    if (!testimonial) {
      return false
    } else {
      return t.id === testimonial.id
    }
  })

  let showBack = useQueryParam('showBack', false)

  return (
    <div className="Project">
      <SEO
        title={categories[0].name + ' project'}
        description={METADATA.project.description}
      />
      {showBack && (
        <Link className="Project_goBack" to="/projects?showBack=true">
          <ChevronLeft />
          <span className="Project_goBack_words--desktop">
            Back to projects
          </span>
          <span className="Project_goBack_words--mobile">Back</span>
        </Link>
      )}
      <PictureGrid pictures={pictures} />
      <div className="Project_details">
        <h1>{street}</h1>
        <div className="Project_categories">
          {categories.map(cat => (
            <span key={cat.name} className="Project_category">
              {cat.name}
            </span>
          ))}
        </div>

        <div className="Project_stats">
          <div className="Project_days">
            <Clock />
            {getWorkingDays(date_started, date_completed)}
          </div>
          <div className="Project_month">
            <Calendar />
            {getMonthAndYear(date_started)}
          </div>
        </div>
      </div>
      <div className="Project_testimonial-wrap">
        {matchedTestimonial && (
          <>
            <p className="Project_testimonial-content">
              {matchedTestimonial.testimonial}
            </p>
            <span className="Project_testimonial-name">
              – {matchedTestimonial.name}
            </span>
          </>
        )}
      </div>
    </div>
  )
}

export const query = graphql`
  query ProjectQuery($id: String) {
    sanityProject(id: { eq: $id }) {
      street
      date_started
      date_completed
      testimonial {
        id
      }
      categories {
        name
      }
      pictures {
        _key
        cover
        asset {
          fluid(maxWidth: 1000) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    allSanityTestimonial {
      nodes {
        id
        name
        testimonial
      }
    }
  }
`

export default Project
