import React, { useState, useCallback, useEffect } from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Button from './Button'
import { ChevronLeft, ChevronRight, Close } from './Icons'
import { motion } from 'framer-motion'
import Slider from 'react-slick'
import './LightBox.scss'

export const Picture = ({ pic, position = 0, index }) => {
  const isPictureActive = position === index
  const pictureClassName = classNames('LightBox_image', {
    'LightBox_image--active': isPictureActive,
  })
  return (
    <div className="LightBox_image-wrapper">
      <Img
        fluid={pic.asset.fluid}
        className={pictureClassName}
        objectFit="contain"
      />
    </div>
  )
}

const LightBox = ({
  pictures,
  isActive,
  setIsActive,
  position,
  setPosition,
}) => {
  const className = classNames('LightBox', {
    active: isActive,
  })

  const [isStart, setIsStart] = useState(position === 0)
  const [isEnd, setIsEnd] = useState(position === pictures.length - 1)
  //set isStart and isEnd when position changes
  useEffect(() => {
    if (position === 0) {
      setIsStart(true)
    } else {
      setIsStart(false)
    }
    if (position === pictures.length - 1) {
      setIsEnd(true)
    } else {
      setIsEnd(false)
    }
  }, [position, pictures.length])

  const goBack = useCallback(() => {
    const newPosition = position - 1
    setPosition(newPosition)
    if (newPosition === 0) {
      setIsStart(true)
    } else {
      setIsStart(false)
      setIsEnd(false)
    }
  }, [position, setPosition])

  const goForward = useCallback(() => {
    const newPosition = position + 1
    setPosition(newPosition)
    if (newPosition === pictures.length - 1) {
      setIsEnd(true)
    } else {
      setIsEnd(false)
      setIsStart(false)
    }
  }, [position, pictures.length, setPosition])

  const handleUserKeyPress = useCallback(
    event => {
      const { keyCode } = event

      // left
      if (keyCode === 37) {
        if (position !== 0) {
          goBack()
        }
      }

      // right
      if (keyCode === 39) {
        if (position !== pictures.length - 1) {
          goForward()
        }
      }

      // esc
      if (keyCode === 27) {
        setIsActive(false)
      }
    },
    [position, pictures.length, goBack, goForward, setIsActive]
  )

  // key presses
  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  // no scroll on body
  useEffect(() => {
    if (isActive) {
      document.querySelector('html').classList.add('noscroll')
    } else {
      document.querySelector('html').classList.remove('noscroll')
    }
  }, [isActive])

  const buttonHover = { scale: 1.05 }
  const buttonTap = { scale: 0.95 }
  return (
    <div className={className}>
      <div className="LightBox_images LightBox_images--desktop">
        {pictures.map((pic, index) => (
          <Picture
            pic={pic}
            index={index}
            position={position}
            key={`desktop-${pic._key}`}
          />
        ))}
      </div>
      <div className="LightBox_images LightBox_images--mobile">
        <Slider>
          {pictures.map((pic, index) => (
            <Picture
              pic={pic}
              index={index}
              position={position}
              key={`mobile-${pic._key}`}
            />
          ))}
        </Slider>
      </div>
      <Button
        onClick={() => setIsActive(false)}
        className="LightBox_close"
        color="accent2"
      >
        <Close />
        close
      </Button>
      <motion.button
        onClick={goBack}
        style={{ display: isStart ? 'none' : 'flex' }}
        className="LightBox_prev"
        whileHover={buttonHover}
        whileTap={buttonTap}
      >
        <ChevronLeft />
      </motion.button>
      <motion.button
        onClick={goForward}
        style={{ display: isEnd ? 'none' : 'flex' }}
        className="LightBox_next"
        whileHover={buttonHover}
        whileTap={buttonTap}
      >
        <ChevronRight />
      </motion.button>
      <span className="LightBox_position">
        {position + 1} / {pictures.length}
      </span>
    </div>
  )
}

LightBox.propTypes = {
  isActive: PropTypes.bool.isRequired,
  setIsActive: PropTypes.func.isRequired,
  pictures: PropTypes.array,
  position: PropTypes.number.isRequired,
  setPosition: PropTypes.func.isRequired,
}

export default LightBox
