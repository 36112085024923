import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import getCoverPicture from '../utilities/getCoverPicture'
import LightBox, { Picture } from './LightBox'
import Button from './Button'
import Slider from 'react-slick'
import isEnterOrSpace from '../utilities/isEnterOrSpace'
import './PictureGrid.scss'

function getOrderedPictures(pictures) {
  const cover = getCoverPicture(pictures)
  const coverIndex =
    pictures.findIndex(pic => pic.cover === true) === -1
      ? 0
      : pictures.findIndex(pic => pic.cover === true)

  if (coverIndex === 0) {
    return pictures
  } else {
    return [
      cover,
      ...pictures.slice(0, coverIndex),
      ...pictures.slice(coverIndex + 1, pictures.length),
    ]
  }
}

const PictureGrid = ({ pictures }) => {
  const cover = getCoverPicture(pictures)
  const orderedPictures = getOrderedPictures(pictures)

  const [isLightBoxActive, setIsLightBoxActive] = useState(false)
  const [position, setPosition] = useState(0)

  const [mobilePosition, setMobilePosition] = useState(0)

  return (
    <>
      <section className="PictureGrid">
        <div
          className="PictureGrid_cover"
          onClick={() => {
            setPosition(0)
            setIsLightBoxActive(true)
          }}
          onKeyDown={e => {
            if (isEnterOrSpace(e)) {
              setPosition(0)
              setIsLightBoxActive(true)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <Img fluid={cover.asset.fluid} />
        </div>
        {orderedPictures.slice(1, 5).map((picture, index) => (
          <div
            onClick={() => {
              setPosition(index + 1)
              setIsLightBoxActive(true)
            }}
            onKeyDown={e => {
              if (isEnterOrSpace(e)) {
                setPosition(index + 1)
                setIsLightBoxActive(true)
              }
            }}
            role="button"
            tabIndex={0}
            className="PictureGrid_top4"
            key={picture._key}
          >
            <Img fluid={picture.asset.fluid} />
          </div>
        ))}
        {pictures.length > 5 && (
          <Button
            onClick={() => setIsLightBoxActive(true)}
            className="PictureGrid_moreButton"
            color="white"
            size="small"
          >
            +{pictures.length - 5} more
          </Button>
        )}
      </section>
      <div className="MobileSlider">
        <Slider
          arrows={false}
          beforeChange={(oldIndex, newIndex) => setMobilePosition(newIndex)}
        >
          {orderedPictures.map((pic, index) => (
            <Picture pic={pic} index={index} key={pic} />
          ))}
        </Slider>
        <span className="MobileSlider_position">
          {mobilePosition + 1} / {pictures.length}
        </span>
      </div>
      <LightBox
        pictures={orderedPictures}
        isActive={isLightBoxActive}
        setIsActive={setIsLightBoxActive}
        position={position}
        setPosition={setPosition}
      />
    </>
  )
}

PictureGrid.propTypes = {
  pictures: PropTypes.array.isRequired,
}

export default PictureGrid
