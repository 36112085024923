export default function workingDaysBetweenDates(startDate, endDate) {
  startDate = new Date(startDate)
  endDate = new Date(endDate)
  // Validate input
  if (endDate < startDate) return 0

  // Calculate days between dates
  var millisecondsPerDay = 86400 * 1000 // Day in milliseconds
  startDate.setHours(0, 0, 0, 1) // Start just after midnight
  endDate.setHours(23, 59, 59, 999) // End just before midnight
  var diff = endDate - startDate // Milliseconds between datetime objects
  var days = Math.ceil(diff / millisecondsPerDay)

  // Subtract two weekend days for every week in between
  var weeks = Math.floor(days / 7)
  days = days - weeks * 2

  // Handle special cases
  var startDay = startDate.getDay()
  var endDay = endDate.getDay()

  // Remove weekend not previously removed.
  if (startDay - endDay > 1) days = days - 2

  // Remove start day if span starts on Sunday but ends before Saturday
  if (startDay === 0 && endDay !== 6) days = days - 1

  // Remove end day if span ends on Saturday but starts after Sunday
  if (endDay === 6 && startDay !== 0) days = days - 1

  if (days === 1) {
    days = days + ' day'
  } else if (days > 1 && days < 14) {
    days = days + ' days'
  } else if (days >= 14 && days <= 60) {
    days = Math.floor(days / 7) + ' weeks'
  } else if (days > 61) {
    days = Math.floor(days / 30) + ' months'
  }

  return days
}
